<template>
  <div>
    <b-overlay
      :show="isBusy"
      rounded="sm"
    >
      <factory-list-add-new
        :is-add-new-factory-sidebar-active.sync="isAddNewFactorySidebarActive"
        @refetch-data="fetchAllFactory"
      />
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <b-card-body>

          <div class="d-flex align-items-center justify-content-end">
            <b-form-group
              class="mb-0 mr-1"
            >
              <b-button
                variant="primary"
                size="lg"
                :disabled="isBusy"
                @click="isAddNewFactorySidebarActive = true"
              >
                <span class="text-nowrap">Add Item</span>
              </b-button>
            </b-form-group>
          </div>
        </b-card-body>
        <div>
          <b-row
            cols-lg="12"
            class="m-1"
          >
            <b-col
              lg="4"
            >
              <Strong>Sites</Strong>
            </b-col>
            <b-col
              lg="4"
            >
              <Strong>Buildings</Strong>
            </b-col>
            <b-col
              lg="4"
            >
              <Strong>Lines</Strong>
            </b-col>
          </b-row>
          <b-row cols-lg="12" class="m-1">
            <b-col
              lg="4"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="site in sites"
                  :key="site.id"
                  :active="site.active"
                  class="d-flex justify-content-between align-items-center"
                  href="#"
                  @click="selectBuilding(site)"
                >
                  <span>{{ site.name }}</span>
                  <span v-if="site.id != 'all'">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <b-icon
                          icon="three-dots-vertical"
                          :variant="site.active ?'light':'primary'"
                        />
                      </template>
                      <b-dropdown-item :to="{ name: 'apps-factory-site-edit', params: { id: site.id } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="deleteThisFactory(site.id)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col
              lg="4"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="site in buildings"
                  :key="site.id"
                  :class="`d-flex justify-content-between align-items-center ${site.active ? 'active' : ''}`"
                  href="#"
                  @click="selectLine(site)"
                >
                  <span>{{ site.name }}</span>
                  <span v-if="site.id != 'all'">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <b-icon
                          icon="three-dots-vertical"
                          :variant="site.active ?'light':'primary'"
                        />
                      </template>
                      <b-dropdown-item :to="{ name: 'apps-factory-edit', params: { id: site.id } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="deleteThisFactory(site.id)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col
              lg="4"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="site in lines"
                  :key="site.id"
                  :class="`d-flex justify-content-between align-items-center ${site.active ? 'active' : ''}`"
                >
                  <span>{{ site.name }}</span>
                  <span v-if="site.id != 'all'">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <b-icon
                          icon="three-dots-vertical"
                          :variant="site.active ?'light':'primary'"
                        />
                      </template>
                      <b-dropdown-item :to="{ name: 'apps-factory-edit', params: { id: site.id } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="deleteThisFactory(site.id)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BRow, BCol, BDropdown, BDropdownItem, BCard, BCardBody, BFormGroup, BButton, BOverlay, BIcon, BIconThreeDotsVertical,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import assetStore from '../../../dashboard/factory/assetFactoryStore'
import FactoryListAddNew from './FactoryListAddNew.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
    FactoryListAddNew,
    BOverlay,
    BIcon,
    BIconThreeDotsVertical,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'factory-assets'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const isAddNewFactorySidebarActive = ref(false)
    const factories = ref([])
    const sites = ref([])
    const buildings = ref([])
    const allBuildings = ref([])
    const allLines = ref([])
    const lines = ref([])
    const isBusy = ref(false)
    const toast = useToast()
    const assets = ref([])

    const groupBy = (list, keyGetter) => {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [item])
        } else {
          collection.push(item)
        }
      })
      return map
    }

    const fetchAllAssets = () => {
      isBusy.value = true
      store
        .dispatch('factory-assets/fetchAllAssets')
        .then(response => {
          isBusy.value = false
          assets.value = response.data
        })
        .catch(e => {
          isBusy.value = false
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching assets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    fetchAllAssets()

    const fetchAllFactory = () => {
      isBusy.value = true
      store.dispatch('factory/fetchAllFactory')
        .then(response => {
          isBusy.value = false
          const grouped = groupBy(response.data, factory => factory.type)
          sites.value = [...[{ id: 'all', name: 'All', active: true }], ...grouped.get('site')]
          buildings.value = [...[{ id: 'all', name: 'All', active: true }], ...grouped.get('building')]
          allBuildings.value = [...[{ id: 'all', name: 'All', active: true }], ...grouped.get('building')]
          lines.value = [...grouped.get('line')]
          allLines.value = [...grouped.get('line')]
          // groupedFactoriesconsole.log(grouped)
          // groupedFactories.value = Array.from(grouped, ([name, value]) => ({ name, children: value }))
        })
        .catch(error => {
          console.log(error)
          isBusy.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching factory list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const deleteThisFactory = id => {
      isBusy.value = true
      store
        .dispatch('factory/deleteFactory', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          fetchAllFactory()
        })
        .catch(() => {
          isBusy.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting item',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    fetchAllFactory()

    const selectBuilding = site => {
      sites.value = sites.value.map(s => ({ ...s, active: s.id === site.id }))
      buildings.value = [...[]]
      lines.value = [...[]]
      // filter assets with siteID === site.id
      if (site.id === 'all') {
        buildings.value = [...allBuildings.value]
        lines.value = [...allLines.value]
      } else {
        const filtered = assets.value.filter(asset => asset.siteID === site.id)
        const grouped = groupBy(filtered, asset => asset.buildingID)
        const filteredBuildings = Array.from(grouped, ([name]) => {
          const building = allBuildings.value.find(item => item.id === name)
          return { ...building, active: false }
        })
        buildings.value = [...[{ id: 'all', name: 'All', active: false }], ...filteredBuildings]
      }
    }

    const selectLine = building => {
      buildings.value = buildings.value.map(b => ({ ...b, active: b.id === building.id }))
      lines.value = [...[]]
      if (building.id === 'all') {
        lines.value = [...allLines.value]
      } else {
        const filtered = assets.value.filter(asset => asset.buildingID === building.id)
        const grouped = groupBy(filtered, asset => asset.lineID)
        const filteredLines = Array.from(grouped, ([name]) => {
          const line = allLines.value.find(item => item.id === name)
          return { ...line, active: false }
        })
        lines.value = [...filteredLines]
      }
    }

    return {

      // Sidebar
      isAddNewFactorySidebarActive,
      fetchAllFactory,
      selectBuilding,
      selectLine,
      isBusy,
      factories,
      sites,
      buildings,
      lines,
      deleteThisFactory,
    }
  },
}
</script>
